/*==============================
=            Widgets           =
==============================*/

.fl-widget {
	&.widget_categories {
		li {
			border-bottom:1px solid #cbccce;
			margin:0;
			padding:5px 0;
			a {
				color:#000;
			}
			&:last-child {
				border-bottom:0;
			}
		}
	}
	.fl-widget-title {
		font-size:36px;
		font-weight:600;
		margin-bottom:5px;
		margin-top:-15px;
	}
}
.widget_sp_image {
	img {
		padding-top:10px;
	}
}