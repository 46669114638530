/*==================================
=            Components            =
==================================*/

.fl-page-content {
	background:transparent;
}
.fl-post-grid-post {
	border-radius:8px;
	box-shadow: 0px 3px 6px -6px #000;
	overflow: hidden;
}
body {
	margin:0;
}
body h2.fl-post-grid-title {
	font-weight:700;
	a {
		&:hover, &:focus {
			color:#22212b;
		}
	}
}
a {
	&:hover, &:focus {
		text-decoration:none;
	}
}
body .fl-post-grid-text, body .fl-post-grid-content p, body .nq-post-grid-text, body .nq-post-grid-content p {
	font-size:15px;
}
body h2.nq-post-grid-title {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px;
    padding: 0;
    font-weight:700;
}
body .nq-post-grid-text, body .nq-post-grid-content p {
	line-height:22px;
}
.nq-post-grid-text {
	padding:20px;
}
.nq-item-wrap {
    border-radius: 8px;
    box-shadow: 0px 3px 6px -6px #000;
    overflow: hidden;
    margin-bottom: 25px;
    background: #fff;
    border: 1px solid #e6e6e6;
}
.nq-post-grid-image img {
	width:100%;
	height:auto;
}
.nq-post-grid {
	text-align:center;
}
.nq-post-grid-post {
	display:inline-block;
	text-align:left;
	float:none;
}
.fl-post-grid-more {
	color:#22212b;
	padding:5px 15px;
	position:relative;
	font-weight:600;
	margin-left:6px;
	margin-top: 10px;
    display: inline-block;
}
.post-wrapper .fl-post-grid-more:hover {
	color:#da251c;
}
.fl-post-grid-more:hover:before {
	border:1px solid #da251c;
}
.fl-post-grid-more i {
	font-size:11px;
	margin-left:1px;
	font-weight:600;
}
.fl-post-grid-post:hover, .nq-item-wrap:hover {
	background:#F8ED40;
}
.nq-item-wrap:hover .fl-post-grid-more {
	color: #22212b;
}
.nq-item-wrap:hover .fl-post-grid-more:before {
	border: 1px solid #696959;
}
.nq-item-wrap:hover .fl-post-grid-more:before {
    border: 1px solid #696959;
}
.fl-post-grid-post:hover .fl-post-grid-more:before {
	border:1px solid #696959;
}
.fl-post-grid-more:hover {
	color:#22212b;
}
.fl-post-grid-more:before {
    content: "";
    border:1px solid #BDBDAE;
    transform: skewX(-25deg);
    width:100%;
    position:absolute;
    top:0;
    left:0;
    height:100%;
}
#breadcrumbs {
	text-align:center;
	font-size:13px;
	padding:25px 0 7px;
	a, span {
		color:#8F8F8F;
	}
}
.withbkg {
	background:#91A1B4;
	display:inline-block;
	p {
		margin:0;
	}
	.fl-module-content {
		margin:5px 30px;
		min-width:120px;
	}
}
ul {
	padding-left:20px;
	li {
		list-style:square;
		line-height:35px;
	}
}
.whitebg {
	background:#fff;
	height:100%;
}
.fl-page-header-fixed .fl-page-nav .navbar-nav a {
	font-size:22px;
}
.fl-post-oimage-image {
	background:#fff;
	margin-bottom:30px;
}
.fl-post-oimage-image img {
	display:block;
	margin:0 auto;
}
.fl-module-content {
	margin:20px 0;
}
.fl-rich-text p {
	line-height:34px;
}
.fl-rich-text table {
	width:100%;
}
.big-lineheight p {
	line-height:50px;
}
.orangebg {
	background:#E7B712;
	padding:30px 60px;
}
.border-top {
	border-top:1px solid #E8E9EB;
}
.border-top:last-child {
	border-bottom:1px solid #E8E9EB;
}
.sub-menu li {
	list-style:none;
}
.fl-post-blog-post {
	background:#fff;
	margin-bottom:50px;
	display:inline-block;
	width:100%;
	.fl-post-blog-title {
		font-size:44px;
		line-height:50px;
	}
	.fl-post-blog-content {
		font-size:17px;
		line-height:30px;
		padding-bottom:30px;
	}
}
.post-wrapper {
	padding:10px 20px;
}
.fl-post-feed-date {
	color:#A4A4A4;
	display:inline-block;
	padding:15px 0;
}
.single-post-wrapper {
	background:#fff;
	margin-left:110px;
	padding:60px 85px;
	.fl-post-date {
		color:#7b7b7b;
		font-size:14px;
		font-weight:300;
		border-bottom:1px solid #ebebeb;
		display:block;
		padding-bottom:5px;
		padding-top:10px;
	}
	.fl-post-header {
		padding:0 20px;
	}
	.fl-post-content {
		line-height:30px;
		padding:0 20px;
		h3 {
			font-size:32px;
			padding-top:10px;
		}
		blockquote {
			font-size:24px;
			font-style:italic;
			font-weight:300;
			line-height:45px;
			border:0;
			padding-left:140px;
			position:relative;
			&:before {
				content:'\“';
				font-size:210px;
				line-height:190px;
				position:absolute;
				left:0;
				top:0;

			}
		}
	}
	.fl-post-title {
		font-size:44px;
	}
}
.single-post-wrapper-comments {
	margin-left:110px;
}
.fl-post-noutati-post {
	&:hover {
		.fl-post-blog-title a, .fl-post-grid-more {
			color: #d32628;
		}
	}
	.fl-post-grid-text {
		padding-top:0;
		padding-bottom:0;
		padding-left:0;
		.post-wrapper {
			padding:0;
			padding-right:20px;
			.fl-post-blog-title {
				font-size:20px;
				font-weight:600;
				margin-top:0;
				margin-bottom:15px;
			}
			p {
				line-height:27px;
			}
			.fl-post-grid-more {
				margin:0;
				padding:0;
				padding-top:5px;
				&:before {
					display:none;
				}
				&:hover {
					color:#d32628;
				}
			}
		}
	}
}
.arrow-down {
	position:relative;
	.down {
		width:65px;
		height:65px;
		background:#fff;
		border-radius:50%;
		z-index:999;
		position:absolute;
		bottom:-25px;
		right:20px;
		cursor:pointer;
		text-align:center;
		&:before {
			content:"\f107";
			font-family:FontAwesome;
			line-height:65px;
			color:#e4bb13;
			display:inline-block;
			font-size:28px;
		}
	}
}
.bx-wrapper {
	.bx-controls-direction {
		a {
			width:60px;
			height:60px;
			background:rgba(231, 183, 18, 0.8);
			border-radius:50%;
			overflow: hidden;
			z-index:9;
			&.bx-next {
				&:before {
					content:"\f105";
					display:block;
					line-height:60px;
					text-align:center;
					font-family: FontAwesome;
					text-indent: 0;
					color:#fff;
					font-size:30px;
				}
			}
			&.bx-prev {
				&:before {
					content:"\f104";
					display:block;
					line-height:60px;
					text-align:center;
					font-family: FontAwesome;
					text-indent: 0;
					color:#fff;
					font-size:30px;
				}
			}
		}
	}
}
.gform_wrapper {
	margin-top:-10px;
	.gform_fields {
		li {
			margin-top:0 !important;
			margin-bottom:10px;
			display:inline-block;
			width:100%;
			label {
				width:20%;
				display:inline-block;
				text-align:right;
				padding-right:10px;
				font-weight:300;
				vertical-align:top;
				padding-top:8px;
			}
			.ginput_container {
				width:80%;
				float:right;
				margin-top:0 !important;
				input, textarea {
					background:#E8E9EB;
					border-radius:0;
					width:100%;
					padding:7px 10px !important;
					height:auto;
				}
				textarea {
					height:104px;
				}
			}
		}
	}
	.gform_footer {
		width:80%;
		float:right;
		margin-top:0;
		input[type="submit"] {
			background:#E7B712;
			border-radius:0;
			text-transform:uppercase;
			border:0;
			padding:18px 40px;
			font-size:24px;
			font-weight:600;
		}
	}
}
.fl-module-icon {
	padding-left:30px;
}
.fl-comments {
	input, textarea {
		border-radius:0;
		padding:8px 15px;
		height:auto;
		&.error {
			border:1px solid #DA251C;
		}
	}
	label {
		font-weight:300;
	}
	input[type="submit"] {
		background:#E7B712;
		border-radius:0;
		text-transform:uppercase;
		border:0;
		padding:18px 40px;
		font-size:24px;
		font-weight:600;
	}
}
div.error {
	color: #DA251C;
	font-size:14px;
	padding-top:4px;
}
.page-title {
	font-size:48px;
	font-weight:600;
	text-align:center;
}
.fl-content-left {
	border:0;
}
.fl-comments-list {
	margin-bottom:40px;
	#comments {
		.comment {
			border-top:1px solid #CCCCCC;
			.comment-content {
				border:0;
			}
			.comment-author-link {
				margin-left:0;
			}
			.comment-content {
				padding:0;
			}
			&:last-child {
				border-bottom:1px solid #CCCCCC;
			}
		}
	}
}
#comment-error-message {
	background:#DA251C;
	font-size:17px;
	color:#fff;
	margin:20px 0;
	padding:20px;
	font-weight:300;
	display:inline-block;
	width:100%;
}
#respond {
	width:65%;
}
#comment-status {
	width:415px;
	height:215px;
	max-width:100%;
	background:#E7B712;
	color:#fff;
	text-align:center;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	display:none;
	font-size:22px;
	font-weight:600;
	p {
		padding-top:70px;
	}
	i {
		position:absolute;
		top:5px;
		right:5px;
		cursor:pointer;
	}
}
.fl-post-meta-bottom {
	border:0;
	margin:0;
}
.fl-comments-list-title, #reply-title {
	font-weight:600;
	font-size:32px;
	margin-bottom:20px;
}
.fl-comments {
	margin-top:50px;
}
.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
	background:transparent;
	border:0;
	padding: 0;
    color:#D9251B;
}
.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
	border:1px solid #D9251B;
}
.gform_wrapper div.validation_error {
	color:#fff;
	background: #D9251B;
    border:0;
    font-weight:400;
    padding: 10px 20px;
    text-align: left;
}
.gform_wrapper .validation_message {
	color:#D9251B;
	color:#fff;
	display:none;
	position:absolute;
	background: #D9251B;
	border-radius:5px;
	padding:0px 10px !important;
	z-index:9999;
	width:auto;
	left:20%;
	bottom:-30px;
}
.fl-page-footer-text a {
	color:#fff;
}
.fl-page-footer-text a:hover {
	color:#fff;
}
.gform_wrapper .validation_message:before {
	content:"";
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #D9251B;
	position:absolute;
	top:-5px;
	left:30px;
}
.gform_wrapper .instruction.validation_message {
	width:100%;
}
.gform_wrapper ul.gform_fields li.gfield {
	padding-right:0;
	position:relative;
}
.gform_wrapper ul.gform_fields li.gfield:hover .validation_message {
	display:block;
}
.toggleMenu .sub-menu {
	position: static !important;
}
.fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
	position:absolute;
}
.page-id-20 .footer-image {
	padding-top:80px;
}
.page-id-111 .footer-image {
	padding-top:50px;
}
.gform_wrapper .gfield_required {
	display:none;
}
.font24 {
	font-family:"roboto";
}
.fl-comment-form #fl-comment-form-submit {
	margin-top:15px;
}
.recaptcha-info {
	font-size:13px;
}
.gallery {
	&.gridb {
		img {
			height:auto;
			display:block;
			margin:0 auto;
		}
	}
}