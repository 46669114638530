@media(max-width: 1410px){
	.fl-row-fixed-width {
		padding:0 20px;
	}
	.fl-post-noutati-image img {
		padding-right:10px;
	}
}
@media(min-width: 1199px) and (max-width: 1397px){
	footer {
		.textwidget {
			font-size:20px;
			line-height:35px;
		}
	}
}
@media(min-width: 1199px) and (max-width: 1410px){
	.fl-post-grid {
		width:100% !important;
	}
	.fl-node-578f5af4274a8 .fl-post-grid-post {
		width:300px;
	}
	.widget_sp_image img {
	    padding-top: 5px;
	}
}
@media(min-width: 992px) and(max-width: 1200px){
	.fl-page-footer-widgets-row {
		aside:last-child {
			width:20%;
		}
		aside:nth-child(2) {
			width:38%;
		}
	}
	footer {
		.textwidget {
			font-size:20px;
			line-height:35px;
		}
	}
}
@media(max-width: 1360px){
	.fl-post-noutati-image img {
		padding-right:20px;
		height:auto;
	}
	.fl-rich-text p br {
		display:none;
	}
}
@media(max-width: 1500px){
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom:420px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap, .fl-node-57a9f4c932c6d > .fl-row-content-wrap, .fl-node-57a9fc0c12150 > .fl-row-content-wrap, .fl-node-57a9ffa20b24d > .fl-row-content-wrap {
		padding-bottom:410px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:0;
	}
}
@media(max-width: 1366px){
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom:380px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap, .fl-node-57a9f4c932c6d > .fl-row-content-wrap, .fl-node-57a9fc0c12150 > .fl-row-content-wrap, .fl-node-57a9ffa20b24d > .fl-row-content-wrap {
		padding-bottom:380px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:0;
	}
}
@media(max-width: 1200px){
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li > a {
		padding:14px 18px;
		font-size:22px;
	}
	.fl-page-footer-widgets-row > div aside:last-child {
		text-align:left;
	}
	.fl-page-nav-right.fl-page-header-fixed .fl-page-nav-wrap .navbar-nav > li > a, .fl-page-header-fixed .fl-page-nav-wrap .navbar-nav > li > a {
		padding:14px 12px;
		font-size:18px;
	}
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom:320px
	}
	.fl-node-578f563345e13 > .fl-row-content-wrap {
		padding-bottom: 320px;
		padding-left:20px;
		padding-right:20px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap {
		padding-bottom:330px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap, .fl-node-57a9ffa20b24d > .fl-row-content-wrap, .fl-node-57a9fc0c12150 > .fl-row-content-wrap, {
		padding-bottom:330px;
	}
	.fl-post-noutati-post .fl-post-grid-text .post-wrapper {
		padding-right:0;
	}
}
@media(max-width: 1024px){
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li > a {
		padding:14px 12px;
		font-size:18px;
	}
	img {
		max-width:100%;
	}
	.font24 {
		span {
			font-size:24px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
		}
	}
	.font32 {
		span {
			font-size:32px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
		}
	}
	.fl-node-578f3e4cc8a48.fl-module-heading .fl-heading {
		text-align:center;
	}
	.fl-post-noutati-post {
		width:100%;
		margin-bottom:30px;
	}
	.fl-node-578f3e4cc8a48 .fl-heading .fl-heading-text {
		font-size:32px;
	}
	.fl-node-578f3e4cc8a48 > .fl-module-content, .fl-node-578f4bb045c93 > .fl-module-content {
		margin-left:0;
	}
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom:300px;
		padding-top:0;
	}
	.fl-node-578f5057544ee > .fl-row-content-wrap {
		padding-top:40px;
	}
	.fl-node-578f563345e13 > .fl-row-content-wrap {
		padding-bottom:280px;
		padding-top:25px;
		padding-left:20px;
		padding-right:20px;
	}
	.fl-node-57a9f4c932c6d > .fl-row-content-wrap {
		padding-bottom:280px;
	}
	.fl-node-57a9ffa20b24d > .fl-row-content-wrap {
		padding-bottom:280px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:0;
	}
	.fl-node-578f5784f3c23 > .fl-row-content-wrap {
		padding-top:20px;
		padding-bottom:20px;
	}
	.fl-post-noutati {
		padding:0 20px;
	}
}
@media(max-width: 991px){
	footer {
		.textwidget {
			font-size:18px;
			line-height:30px;
		}
		aside:nth-child(3) {
			clear:both;
			padding-left:50px;
		}
	}
	.fl-page-footer-widgets .fl-page-footer-widgets-row aside {
		margin-bottom:30px;
	}
	.widget_sp_image img {
		padding-top:3px;
	}

	.fl-page-footer-widgets-row {
		aside:nth-child(2) {
			width:75%;
		}
	}
}
@media(max-width: 767px){
	.fl-page-nav-bottom {
		.fl-page-header-content {
			padding-top:12px;
			.fl-page-header-text {
				font-size:23px;
			}
		}
	}
	footer {
		aside:nth-child(3) {
			padding-left:20px;
		}
	}
	.fl-page-nav-bottom {
		.fl-page-nav-wrap {
			padding-top:5px;
		}
	}
	.fl-row-fixed-width, .fl-post-noutati, .fl-post-noutati-image img {
		padding:0;
	}
	.fl-post-feed-date {
		padding:0;
	}
	.fl-post-grid-more {
		float:left;
		margin:25px 0;
		margin-bottom:40px;
	}
	.single-post-wrapper {
		margin:0;
		padding:0;
	}
	.fl-icon-wrap {
		float:left;
	}
	.single-post-wrapper .fl-post-title {
		font-size:32px;
	}
	.single-post-wrapper-comments {
		margin:0;
	}
	#respond {
		width:100%;
	}
	.fl-page-nav {
		.navbar-toggle {
			background:#fff;
			padding: 8px 25px;
			span {
				color:#000;
				float:left;
				text-transform:uppercase;
				&:before {
					content:"\f0c9";
					display:inline-block;
					vertical-align:sub;
					font-size:42px;
					font-family:FontAwesome;
					margin-right:20px;
					line-height:42px;
				}
			}
		}
	}
	h1 {
		font-size:36px;
	}
	.fl-heading {
		text-align:center !important;
		.fl-heading-text {
			br {
				display:none;
			}
		}
	}
	img {
		max-width:100%;
	}
	.fl-module-content {
		margin-left:0 !important;
	}
	.fl-node-578f3e4cc8a48 > {
		.fl-module-content {
			margin-left:0;
		}
	}
	.font24 {
		span {
			font-size:24px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
		}
		.fl-rich-text {
			text-align:center;
		}
	}
	.font32 {
		span {
			font-size:32px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
		}
		.fl-rich-text {
			text-align:center;
		}
	}
	.font36 {
		span {
			font-size:36px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
		}
		.fl-rich-text {
			text-align:center;
		}
	}
	.font16 {
		span {
			font-size:16px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
			font-size:16px;
			line-height:30px;
		}
		.fl-rich-text {
			text-align:center;
		}
		.fl-module-content {
			margin:8px 0;
		}
	}
	.font28 {
		span, .fl-heading {
			font-size:28px !important;
			line-height:34px;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
		}
		.fl-rich-text {
			text-align:center;
		}
		.fl-module-content {
			margin:8px 0;
		}
	}
	.whitebg {
		&.font28 {
			padding:20px;
			margin-bottom:20px;
			p {
				display:block;
				text-align:left;
			}
		}
		&.mob-full {
			margin-top:0;
			.orangebg {
				padding:30px 20px;
				p {
					text-align:center !important;
				}
			}
		}
	}
	.font20 {
		span, .fl-heading {
			font-size:20px !important;
		}
		p {
			text-align:center;
			padding:0;
			margin:0;
			display:inline;
			line-height:26px;
		}
		.fl-rich-text {
			text-align:center;
		}
		.fl-module-content {
			margin:8px 0;
		}
	}
	.fl-post-blog {
		padding:20px;
		.fl-post-blog-post {
			margin-bottom:30px;
			.fl-post-blog-title {
			    font-size: 32px;
			    line-height: 36px;
			}
			.fl-post-blog-content {
				display:none;
			}
			.post-wrapper {
				padding-bottom:40px;
			}
		}
	}
	.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
		line-height:26px;
	}
	td {
		display:block;
	}
	.big-lineheight .fl-rich-text {
		text-align:center;
	}
	.mob-full .fl-row-content-wrap {
		padding:0 !important;
	}
	.mob-full .fl-module-rich-text {
		padding:0 20px;
	}
	.mob-full .fl-module-rich-text .fl-module-content {
		margin-right:0;
	}
	.mob-full {
		margin-top:20px;
	}
	.withbkg .fl-module-content {
		margin: 3px 25px !important;
	}
	.withbkg {
		margin-top:40px;
		margin-bottom:20px;
	}
	.fl-module img {
		height:auto;
	}
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom:240px;
	}
	.fl-node-5790d38518725 > .fl-row-content-wrap {
		margin-bottom:0;
	}
	.fl-node-578f5057544ee > .fl-row-content-wrap {
		padding-top:20px;
		padding-bottom:30px;
	}
	.fl-node-578f563345e13 > .fl-row-content-wrap {
		padding-top: 30px;
    	padding-bottom: 220px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap {
		padding-bottom:230px;
	}
	.fl-node-57a9ffa20b24d > .fl-row-content-wrap {
		padding-bottom:220px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:0;
	}
	.fl-post-noutati {
		margin:0;
	}
	.fl-page-nav .navbar-toggle:hover, .fl-page-nav .navbar-toggle:focus {
		background:transparent;
	    padding: 8px 25px;
	    border-bottom: 1px solid #e6e6e6;
	}
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container {
		padding:0;
	}
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li > a {
		color:#000;
		font-size:24px;
	}
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li > a:after {
		content:"\f105";
		float:right;
		font-family: FontAwesome;
	}
	.fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li:hover a, .fl-page-nav-bottom .fl-page-nav-wrap .fl-page-nav-container .fl-page-nav .navbar-nav > li:hover a:after {
		color:#DE4038;
	}
	.fl-node-578f5784f3c23 > .fl-row-content-wrap {
		padding-top:50px;
	}
	.arrow-down .down {
		display:none;
	}
	.fl-row-content-wrap {
		padding-right:20px !important;
		padding-left:20px !important;
	}
	.fl-post-noutati-post .fl-post-grid-text .post-wrapper .fl-post-blog-title {
		margin-top:10px;
	}
	.fl-post-noutati-post .fl-post-grid-text .post-wrapper, .fl-post-noutati-post .fl-post-grid-text {
		padding-right:0;
	}
	.fl-post-noutati-post {
		margin-bottom:40px;
	}
	.fl-post-noutati-post:last-child {
		margin-bottom:0;
	}
	.fl-page-footer-widgets-row {
		padding-left:20px;
		.fl-widget {
			text-align:left;
		}
	}
	.fl-module-post-grid {
		padding-top:20px;
	}
	.fl-node-578f5c1072e9d > .fl-row-content-wrap {
		padding-top:0;
	}
	.fl-node-578f627245533 > .fl-row-content-wrap {
		padding-top:0;
	}
	.fl-node-578f3dad3d593 > .fl-row-content-wrap,  .fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-top:0;
	}
	.footer-image {
		padding-top:60px;
	}
	.fl-node-578f627cc8a5f > .fl-module-content {
		margin-top:0;
	}
	#breadcrumbs {
		padding:0;
		padding-top:10px;
	}
}
@media(max-width: 640px){
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom: 180px;
	}
	.fl-node-578f563345e13 > .fl-row-content-wrap {
		padding-bottom: 180px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap {
		padding-bottom:190px;
	}
	.fl-node-57a9f4c932c6d > .fl-row-content-wrap, .fl-node-57a9ffa20b24d > .fl-row-content-wrap, .fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:180px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom:0;
	}
}
@media(max-width: 410px){
	.fl-node-578f3dad3d593 > .fl-row-content-wrap {
		padding-bottom: 110px;
	}
	.fl-node-57a9fc0c12150 > .fl-row-content-wrap {
		padding-bottom: 0px;
	}
	.fl-node-578f563345e13 > .fl-row-content-wrap {
		padding-bottom: 100px;
	}
	.fl-node-5790c80b432ff > .fl-row-content-wrap {
		padding-bottom:120px;
	}
	.fl-node-57a9f4c932c6d > .fl-row-content-wrap, .fl-node-57a9ffa20b24d > .fl-row-content-wrap {
		padding-bottom:110px;
	}
}
@media(max-width: 460px){
	.nq-post-grid-post {
		width:100%;
	}
}