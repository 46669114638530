/*==============================
=            Header            =
==============================*/

.fl-page-nav-bottom {
	background:transparent;
	.fl-page-header-content {
		padding-top:24px;
		.fl-page-header-text {
			font-size:38px;
			color:#D9251B;
			margin:0;
			strong {
				color:#22212b;
			}
			a {
				color:#22212b;
				&:hover {
					color:#22212b;
				}
			}
		}
	}
	.fl-page-nav-wrap {
		border:0;
		background:transparent;
		padding-top:20px;
		.fl-page-nav-container {
			background:#fff;
			box-shadow: -1px 5px 3px -3px #dedede;
			padding:0 15px;
			.fl-page-nav {
				.navbar-nav {
					> li {
						list-style:none;
						> a {
							padding:14px 22px;
						}
						.sub-menu {
							li {
								list-style:none;
							}
						}
					}
				}
			}
		}
	}
}