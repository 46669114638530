/*==============================
=            Footer            =
==============================*/

.fl-page-footer {
	border:0;
	font-size:20px;
	padding-bottom:25px;
}
.fl-page-footer-widgets {
	border:0;
	font-size:24px;
	line-height:45px;
	padding-top:23px;
	border-top:8px solid #323235;
	.fl-page-footer-widgets-row {
		aside {
			margin-bottom:0;
			&:nth-child(2) {
				padding-right:80px;
			}
			i {
				margin-left:-34px;
				font-size:30px;
				margin-right:10px;
				vertical-align:middle;
				float:left;
				padding-top:8px;
			}
			a {
				color:#fff;
			}
			img {
				height:auto;
			}
		}
	}
}
.footer-image {
	margin-bottom:-19px;
	padding-top:130px;
}
.fl-page-footer a.fl-icon {
	color:#fff;
	border:1px solid #fff;
	width:40px;
	height:40px;
	text-align:center;
	line-height:40px;
	display:inline-block;
}
.fl-icon-facebook-circle:before {
	content: "\f09a";
	font-family:FontAwesome;
	font-size:22px;
}
.fl-icon-youtube-circle:before {
    content: "\f16a";
	font-family:FontAwesome;
	font-size:22px;
}
.fl-page-footer-container {
	padding:18px 0 0;
}
.fl-page-footer .text-left .fl-social-icons .fl-icon {
	margin: 0 20px 0 0;
}
.fl-page-footer-text {
	padding-top:10px;
}
